import React, { useEffect, useState } from "react";
import style from "./index.module.less"
import LangList from '../footer/NewLangList'
import CheckIpClose from '@/images/SVG/checkip_close.svg'
import getCountry from '@/utils/getCountry.js'
import { langList, regionAll, ipCode } from '@/utils/regions.js'
import { getIp } from '@/services/request'
import useBigScreen from "@/hooks/bigScreen"

const CheckIpBox = ({  }) => {
    const country = getCountry()
    const [showBox, setShowBox] = useState(false)
    const [goRegion, setGoRegion] = useState('base')
    const ifPc=useBigScreen(768)
    const recommend_text_list={
        cn:{
            btn:"前往",
            text:'我们注意到您在中国，是否要访问中国站点？'
        },
        base:{
            btn:"Go",
            text:'We noticed that you are not in a supported region, would you like to visit our global site?'
        },
        us:{
            btn:"Go",
            text:'We noticed that you are in the United States, would you like to visit the US site? '
        }, 
        jp:{
            btn:"行く",
            text:'日本にいることを確認しました。日本のサイトに移動しますか？'
        },
        kr:{
            btn:"이동",
            text:'한국에 계신 것을 확인했습니다. 한국 사이트로 이동하시겠습니까?'
        }, 
        th:{
            btn:"ไป",
            text:'เราเห็นว่าคุณอยู่ในประเทศไทย คุณต้องการไปที่เว็บไซต์ประเทศไทยหรือไม่?'
        }, 
        uk:{
            btn:"Go",
            text:'We noticed that you are in the United Kingdom, would you like to visit the UK site?'
        },
        de:{
            btn:"Gehen",
            text:'Wir haben bemerkt, dass Sie sich in Deutschland befinden. Möchten Sie die deutsche Seite besuchen? '
        },
        it:{
            btn:"Vai",
            text:'Abbiamo notato che sei in Italia, vuoi visitare il sito italiano?'
        },
        fr:{
            btn:"Aller",
            text:'Nous avons remarqué que vous êtes en France. Souhaitez-vous visiter le site français ?'
        },
        'nl-en':{
            btn:"Go",
            text:'We noticed that you are in the Netherlands, would you like to visit the Dutch site (in English)?'
        },  
        'cz-en':{
            btn:"Go",
            text:'We noticed that you are in the Czech Republic, would you like to visit the Czech site (in English)?'
        },
        'es-en':{
            btn:"Go",
            text:'We noticed that you are in Spain, would you like to visit the Spanish site (in English)?'
        }
    }
    useEffect(() => {
        if (!!showBox) {
            typeof sensors !== "undefined" && sensors.track('module_view', {
                custom: {
                    module_id: 20038
                }
            });
        }
    }, [showBox])
    useEffect(() => {
        // 判断localStorage是否用户关闭cookie
        const ifSetClose = localStorage.getItem(`if-set-checkip-close`);
        // 有的话不显示
        if (!!ifSetClose) {
            const now = new Date();
            if (now.getTime() > ifSetClose) {
                // 过了一天了已过期
                localStorage.removeItem(`if-set-checkip-close`);
                setShowBox(true)
            } else {
                setShowBox(false)
            }
        } else {
            getIp().then((res) => {
                const { code, data } = res?.data
                if (code == 2000) {
                    let userIp = data.country
                    const keys = Object.keys(ipCode).map(key => key);
                    let userIpCode = ''
                    if (!keys.includes(userIp)) {
                        userIpCode = 'base'
                    } else {
                        userIpCode = ipCode[userIp]
                    }
                    if (country.includes(userIpCode)) {
                        setShowBox(false)
                    } else {
                        setGoRegion(userIpCode)
                        setShowBox(true)
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }, [])

    useEffect(()=>{
        console.log(goRegion,'goRegion')
    },[goRegion])
    const closeBox = () => {
        setShowBox(false)
        const now = new Date();
        const expiry_time = now.getTime() + 24 * 60 * 60 * 1000
        localStorage.setItem(`if-set-checkip-close`, expiry_time)
        // 存放localStorage 有效期1天
    }
    //前往推荐国家
    const goRegionLink = (region, lang) => {
        const ifUrlHaveIp = regionAll.indexOf(window.location.pathname.split('/')[1]) > -1;
        let path = window.location.pathname
        if (ifUrlHaveIp) {
            path = `/${path.split('/').slice(2).join('/')}`;
        }

        if (region == "base") {
            window.localStorage.setItem("region", region.toUpperCase())
            window.localStorage.setItem("lang", lang)
            window.location.pathname = path
        } else if (goRegion == 'cn') {
            window.localStorage.setItem("region", region.toUpperCase())
            window.localStorage.setItem("lang", lang)
            window.location.href = `${process.env.url_cn}/${path.substring(1)}`
        } else {
            window.localStorage.setItem("region", region.toUpperCase())
            window.localStorage.setItem("lang", lang)
            window.location.pathname = `/${region}${path}`
        }
        typeof sensors !== "undefined" && sensors.track('module_interaction', {
            custom: {
                module_id: 20038
            }
        });


    }
    return (
        <>
            {showBox &&
                (!!ifPc ?
                    <div className={style.checkip}>
                        <div className={style.checkipBox}>
                            <div className={style.checkipDesc}>
                                {recommend_text_list[goRegion].text}

                            </div>
                            <div className={style.checkipBoxCountry}>
                                <LangList country={country} ifMenu={true} goRegion={goRegion} />

                            </div>
                            <div className={style.checkipBoxGo} onClick={() => goRegionLink(goRegion, langList[goRegion])}>
                            {recommend_text_list[goRegion].btn}
                            </div>
                        </div>

                        {/* <div className={style.checkipBox}> */}

                            <div className={style.checkipBoxClose} onClick={closeBox}>
                                <CheckIpClose />
                            </div>

                        {/* </div> */}
                    </div> :
                    <div className={style.checkipMb}>
                        <div className={style.checkipMbContent}>
                            <div className={style.checkipMbDesc}>
                            {recommend_text_list[goRegion].text}
                            </div>
                            <div className={style.checkipMbSelect}>
                                <div className={style.checkipMbCountry}>
                                    <LangList country={country} ifMenu={true} goRegion={goRegion} />

                                </div>
                                <div className={style.checkipMbGo} onClick={() => goRegionLink(goRegion, langList[goRegion])}>
                                {recommend_text_list[goRegion].btn}
                                </div>
                            </div>
                        </div>
                        <div className={style.checkipMbClose} onClick={closeBox}>
                            <CheckIpClose />
                        </div>
                    </div>
                )
            }
        </>

    )
}

export default CheckIpBox



