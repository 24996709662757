import React from 'react'
import style from './index.module.less'
import { Link } from "gatsby"
import useNavigateLink from "@/hooks/useNavigateLink";
import useGa from '@/hooks/useGa'
import { useIntl } from 'react-intl';
import getCountry from '@/utils/getCountry.js'
import { buy_link } from '@/scripts/constant.js'



const FooterList = () => {
    const intl = useIntl()
    const region = getCountry()
    const footer_list_message = [
        {
            title: intl.formatMessage({ id: 'menu.products', defaultMessage: 'PRODUCTS' }),
            list: [
                {
                    key: "air2ultra",
                    name: "Air 2 Ultra",
                    link: "/air2ultra",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                },
                {
                    key: "air2",
                    name: "Air 2 / Pro",
                    link: "/air2",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                },
                {
                    key: "air",
                    name: "Air",
                    link: "/air",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                }, {
                    key: "beampro",
                    name: "XREAL Beam Pro",
                    link: "/beampro",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                }, {
                    key: "beam",
                    name: "Beam",
                    link: "/beam",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                }, {
                    key: "hub",
                    name: "Hub",
                    link: buy_link[region]['hub'].shop,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                },
                {
                    key: "adapter",
                    name: "Adapter",
                    link: "/adapter",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                }
            ]
        }, {
            title: intl.formatMessage({ id: 'footer.social', defaultMessage: 'SOCIAL' }),
            list: [
                {
                    key: "twitter",
                    name: "Twitter",
                    link: region == 'jp' ? process.env.url_twitter_jp : process.env.url_twitter,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                },
                {
                    key: 'instagram',
                    name: "Instagram",
                    link: region == 'jp' ? process.env.url_ins_jp : process.env.url_ins,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                }, {
                    key: "reddit",
                    name: "Reddit",
                    link: process.env.url_reddit,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                }, {
                    key: "youtube",
                    name: "Youtube",
                    link: region == 'jp' ? process.env.url_youtube_jp : process.env.url_youtube,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                },
            ]
        }, {
            title: intl.formatMessage({ id: "footerList.about", defaultMessage: 'About' }),
            list: [
                {
                    key: "about",
                    name: intl.formatMessage({ id: "footer.aboutus", defaultMessage: 'About Us' }),
                    link: "/about",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                },
                {
                    key: "contact",
                    name: intl.formatMessage({ id: "footerList.contact", defaultMessage: 'Contact Us' }),
                    link: "/contact-us",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                },
                {
                    key: "career",
                    name: intl.formatMessage({ id: "footerList.join", defaultMessage: 'Join Us' }),
                    link: "/career",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                }, {
                    key: "distributors",
                    name: intl.formatMessage({ id: "distributor_title" }),
                    link: "/distributors",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                },
                {
                    key: "pioneers",
                    name: "Pioneers Program",
                    link: "/pioneers",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                }
            ]
        }, {
            title: intl.formatMessage({ id: "footerList.developer", defaultMessage: 'Developer' }),
            list: [
                {
                    key: "developer",
                    name: intl.formatMessage({ id: "footer.developerhome", defaultMessage: 'Developer Home' }),
                    link: process.env.url_developer,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                },
                {
                    key: "sdk",
                    name: intl.formatMessage({ id: "footerList.sdk_download", defaultMessage: 'SDK Download' }),
                    link: `${process.env.url_developer}/download`,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                }, {
                    key: "discord",
                    name: intl.formatMessage({ id: "footerList.discord", defaultMessage: 'Discord' }),
                    link: region == 'jp' ? process.env.url_discord_jp : process.env.url_discord,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                }
            ]
        }
    ]
    const footer_list_message_jp = [
        {
            title: intl.formatMessage({ id: 'menu.products', defaultMessage: 'PRODUCTS' }),
            list: [
                {
                    key: "air2ultra",
                    name: "Air 2 Ultra",
                    link: "/air2ultra",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                },
                {
                    key: "air2",
                    name: "Air 2 / Pro",
                    link: "/air2",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                },
                {
                    key: "air",
                    name: "Air",
                    link: "/air",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                }, {
                    key: "beampro",
                    name: "XREAL Beam Pro",
                    link: "/beampro",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                }, {
                    key: "beam",
                    name: "Beam",
                    link: "/beam",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                }, {
                    key: "hub",
                    name: "Hub",
                    link: buy_link[region]['hub'].shop,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                },
                {
                    key: "adapter",
                    name: "Adapter",
                    link: "/adapter",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                }
            ]
        }, {
            title: intl.formatMessage({ id: 'footer.social', defaultMessage: 'SOCIAL' }),
            list: [
                {
                    key: "twitter",
                    name: "Twitter",
                    link: region == 'jp' ? process.env.url_twitter_jp : process.env.url_twitter,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                },
                {
                    key: 'instagram',
                    name: "Instagram",
                    link: region == 'jp' ? process.env.url_ins_jp : process.env.url_ins,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                }, {
                    key: "reddit",
                    name: "Reddit",
                    link: process.env.url_reddit,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                }, {
                    key: "youtube",
                    name: "Youtube",
                    link: region == 'jp' ? process.env.url_youtube_jp : process.env.url_youtube,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                },
            ]
        }, {
            title: intl.formatMessage({ id: "footerList.about", defaultMessage: 'About' }),
            list: [
                {
                    key:"news",
                    name: 'News',
                    link: "/news",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                },
                {
                    key: "about",
                    name: intl.formatMessage({ id: "footer.aboutus", defaultMessage: 'About Us' }),
                    link: "/about",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                },
                {
                    key: "contact",
                    name: intl.formatMessage({ id: "footerList.contact", defaultMessage: 'Contact Us' }),
                    link: "/contact-us",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                },
                {
                    key: "career",
                    name: intl.formatMessage({ id: "footerList.join", defaultMessage: 'Join Us' }),
                    link: "/career",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                }, {
                    key: "distributors",
                    name: intl.formatMessage({ id: "distributor_title" }),
                    link: "/distributors",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                },
                {
                    key: "pioneers",
                    name: "Pioneers Program",
                    link: "/pioneers",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                },{
                    key:"airstores",
                    name: '体験店舗リスト',
                    link: "/air/stores",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                }
            ]
        }, {
            title: intl.formatMessage({ id: "footerList.developer", defaultMessage: 'Developer' }),
            list: [
                {
                    key: "developer",
                    name: intl.formatMessage({ id: "footer.developerhome", defaultMessage: 'Developer Home' }),
                    link: process.env.url_developer,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                },
                {
                    key: "sdk",
                    name: intl.formatMessage({ id: "footerList.sdk_download", defaultMessage: 'SDK Download' }),
                    link: `${process.env.url_developer}/download`,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                }, {
                    key: "discord",
                    name: intl.formatMessage({ id: "footerList.discord", defaultMessage: 'Discord' }),
                    link: region == 'jp' ? process.env.url_discord_jp : process.env.url_discord,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                }
            ]
        }
    ]
    const footer_list_message_kr = [
        {
            title: intl.formatMessage({ id: 'menu.products', defaultMessage: 'PRODUCTS' }),
            list: [
                {
                    key: "air2ultra",
                    name: "Air 2 Ultra",
                    link: "/air2ultra",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                },
                {
                    key: "air2",
                    name: "Air 2 / Pro",
                    link: "/air2",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                },
                {
                    key: "air",
                    name: "Air",
                    link: "/air",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                }, {
                    key: "beampro",
                    name: "XREAL Beam Pro",
                    link: "/beampro",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                }, {
                    key: "beam",
                    name: "Beam",
                    link: "/beam",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                },
                {
                    key: "adapter",
                    name: "Adapter",
                    link: "/adapter",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                }
            ]
        }, {
            title: intl.formatMessage({ id: 'footer.social', defaultMessage: 'SOCIAL' }),
            list: [
                {
                    key: "twitter",
                    name: "Twitter",
                    link: region == 'jp' ? process.env.url_twitter_jp : process.env.url_twitter,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                },
                {
                    key: 'instagram',
                    name: "Instagram",
                    link: region == 'jp' ? process.env.url_ins_jp : process.env.url_ins,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                }, {
                    key: "reddit",
                    name: "Reddit",
                    link: process.env.url_reddit,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                }, {
                    key: "youtube",
                    name: "Youtube",
                    link: region == 'jp' ? process.env.url_youtube_jp : process.env.url_youtube,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                },
            ]
        }, {
            title: intl.formatMessage({ id: "footerList.about", defaultMessage: 'About' }),
            list: [
                {
                    key: "about",
                    name: intl.formatMessage({ id: "footer.aboutus", defaultMessage: 'About Us' }),
                    link: "/about",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                },
                {
                    key: "contact",
                    name: intl.formatMessage({ id: "footerList.contact", defaultMessage: 'Contact Us' }),
                    link: "/contact-us",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                },
                {
                    key: "career",
                    name: intl.formatMessage({ id: "footerList.join", defaultMessage: 'Join Us' }),
                    link: "/career",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                }, {
                    key: "distributors",
                    name: intl.formatMessage({ id: "distributor_title" }),
                    link: "/distributors",
                    sensorID: "",
                    gaID: "",
                    outlink: false
                },
                {
                    key:"pioneers",
                    name:"Pioneers Program",
                    link:"/pioneers",
                    sensorID:"",
                    gaID:"",
                    outlink:false
                }
            ]
        }, {
            title: intl.formatMessage({ id: "footerList.developer", defaultMessage: 'Developer' }),
            list: [
                {
                    key: "developer",
                    name: intl.formatMessage({ id: "footer.developerhome", defaultMessage: 'Developer Home' }),
                    link: process.env.url_developer,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                },
                {
                    key: "sdk",
                    name: intl.formatMessage({ id: "footerList.sdk_download", defaultMessage: 'SDK Download' }),
                    link: `${process.env.url_developer}/download`,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                }, {
                    key: "discord",
                    name: intl.formatMessage({ id: "footerList.discord", defaultMessage: 'Discord' }),
                    link: region == 'jp' ? process.env.url_discord_jp : process.env.url_discord,
                    sensorID: "",
                    gaID: "",
                    outlink: true
                }
            ]
        }
    ]
    const footer_list_arr = region == 'kr' ? footer_list_message_kr : (region=='jp'?footer_list_message_jp:footer_list_message)
    const getLinkUrl = (link) => {
        const url = useNavigateLink(link)
        return url
    }
    const addGa = (cate) => {
        useGa('Menu', cate, 'Click')
    }
    const addSensor = (id, product_name) => {
        if (!!product_name) {
            typeof sensors !== "undefined" && sensors.track('module_interaction', {
                custom: {
                    module_id: id,
                    products_name: product_name
                }
            });
        } else {
            typeof sensors !== "undefined" && sensors.track('module_interaction', {
                custom: {
                    module_id: id
                }
            });
        }

    }
    return (
        <div className={style.footerList}>
            {
                footer_list_arr.map((item, index) => (
                    <div className={style.footerListItem} key={`footerListItem${index}`}>
                        <div className={style.footerListItemTitle}>
                            {item.title}

                        </div>
                        <div className={style.footerListItemBox}>
                            {item.list.map((value, key) => (
                                <div className={style.footerListItemBoxValue} key={`footerListItemBoxValue${index}-${key}`}
                                    onClick={() => { addGa(value.gaID), addSensor(value.sensorID) }}
                                >
                                    {
                                        !!value.outlink ? <a href={value.link} target='_blank'>{value.name}</a> : <Link to={getLinkUrl(value.link)}>
                                            {value.name}
                                        </Link>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default FooterList