import React from "react"

const kol_data = {
  list1: [
    {
      img:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/1.webp",
      icon:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/icon1.webp",
      name: "Cas and Chary VR",
      desc: 'A RESIZABLE 330" OLED TV In Your Pocket! XREAL Air + Beam',
      media: "youtube",
      link: "https://youtu.be/IFyR-eOK3Ng",
    },
    {
      img:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/2.webp",
      icon:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/icon2.webp",
      name: "SarahGrace",
      desc: "I Wore Smart Glasses for 100 Hours… This is What Happened",
      media: "youtube",
      link: "https://www.youtube.com/watch?v=Ean2vYYlXhw",
    },
    {
      img:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/3.webp",
      icon:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/icon3.webp",
      name: "The Tech Chap",
      desc:
        "XREAL Air + XREAL Beam Unboxing & Setup - 330-inch OLED Glasses! [2023]",
      media: "youtube",
      link: "https://www.youtube.com/watch?v=VU9j5yhsyZk",
    },
    {
      img:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/4.webp",
      icon:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/icon4.webp",
      name: "Created by Ella",
      desc: "My First AR GLASSES Experience | XREAL Air & XREAL Beam",
      link: "https://www.youtube.com/watch?v=yrk_WfbgG5gMy",
      media: "youtube",
    },
    {
      img:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/5.webp",
      icon:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/icon5.webp",
      name: "Scrubzah",
      desc: "Kid Plays Fortnite With AR GLASSES... (XREAL!)",
      link: "https://www.youtube.com/watch?v=eLfRzvDw_AI",
      media: "youtube",
    },
    {
      img:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/6.webp",
      icon:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/icon6.webp",
      name: "Nikias Molina",
      desc: "How these AR Glasses Bring the Apple Ecosystem to Life",
      media: "youtube",
      link: "https://www.youtube.com/watch?v=rpc5KxsYFXM",
    },
    {
      img:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/7.webp",
      icon:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/icon7.webp",
      name: "Jon Rettinger",
      desc: "Get AR now! XREAL Beam",
      media: "youtube",
      link: "https://www.youtube.com/watch?v=GZwAmqhlEm4",
    },
    {
      img:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/8.webp",
      icon:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/icon8.webp",
      name: "Brian Tong",
      desc:
        "XREAL Air & XREAL Beam First Look! These 130-inch Display AR Glasses just got better!",
      media: "youtube",
      link: "https://www.youtube.com/watch?v=vO2VeihDVC0",
    }, {
      img:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/9.webp",
      icon:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/icon9.webp",
      name: "Andres Vidoza",
      desc: "I Used Smart AR Glasses on MacBook Air for 2 Months!",
      media: "youtube",
      link: "https://www.youtube.com/watch?v=Sn1DKAKXdn4",
    },
    {
      img:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/10.webp",
      icon:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/icon10.webp",
      name: "JuanBagnell",
      desc: "XREAL Beam: AR for ALL of us!",
      link: "https://www.youtube.com/watch?v=TzwmOHm-WR8",
      media: "youtube",
    },
    {
      img:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/11.webp",
      icon:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/icon11.webp",
      name: "AuthenTech - Ben Schmanke",
      desc: "A Foretaste of Apple Vision! (XREAL Air AR Glasses)",
      link: "https://www.youtube.com/watch?v=EYJiaYlP8FE&list=PLL5TxFqWatXeIoE6_gmCPt-Jhkr-m5exg&index=12",
      media: "youtube",
    },
    {
      img:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/12.webp",
      icon:
        process.env.resourceUrl + "images/playType/kol/spatialDisplay/icon12.webp",
      name: "Shane Starnes",
      desc: 'Spatial AR on 330" Screen with XREAL Beam! XREAL Air vs Rokid Air',
      link: "https://www.youtube.com/watch?v=ecjD2c9FJxQ&list=PLL5TxFqWatXeIoE6_gmCPt-Jhkr-m5exg&index=11",
      media: "youtube",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/arSpace/1.webp",
      icon: process.env.resourceUrl + "images/playType/kol/arSpace/icon1.webp",
      name: "iJustine",
      desc: "REACTING TO NREAL AR GLASSES!! Wow!!",
      link: "https://www.youtube.com/watch?v=O042d3FPRZY&list=PLL5TxFqWatXeIoE6_gmCPt-Jhkr-m5exg&index=13",
      media: "youtube",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/arSpace/2.webp",
      icon: process.env.resourceUrl + "images/playType/kol/arSpace/icon2.webp",
      name: "Andres Vidoza",
      desc: "Trying Smart Glasses for 1 Month | XREAL Air AR Glasses",
      link: "https://www.youtube.com/watch?v=WWI0TeZzfrw&list=PLL5TxFqWatXeIoE6_gmCPt-Jhkr-m5exg&index=14",
      media: "youtube",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/arSpace/4.webp",
      icon: process.env.resourceUrl + "images/playType/kol/arSpace/icon4.webp",
      name: "Bitwit",
      desc: "You've Gotta See These AR Glasses! - Xreal Air",
      link: "https://www.youtube.com/watch?v=cJOovSQNp3s&list=PLL5TxFqWatXeIoE6_gmCPt-Jhkr-m5exg&index=15",
      media: "youtube",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/arSpace/5.webp",
      icon: process.env.resourceUrl + "images/playType/kol/arSpace/icon5.webp",
      name: "JerryRigEverything",
      desc: "The Smart Glasses of the Future are here! - (Teardown)",
      link: "https://www.youtube.com/watch?v=8gM4x12RSzE&list=PLL5TxFqWatXeIoE6_gmCPt-Jhkr-m5exg&index=16",
      media: "youtube",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/arSpace/6.webp",
      icon: process.env.resourceUrl + "images/playType/kol/arSpace/icon6.webp",
      name: "Mike O'Brien",
      desc: "I Tried Smart Glasses for 7 Days (XREAL Air AR Glasses)",
      link: "https://www.youtube.com/watch?v=Oq-n9WJBI0Q&list=PLL5TxFqWatXeIoE6_gmCPt-Jhkr-m5exg&index=17",
      media: "youtube",
    }, {
      img: process.env.resourceUrl + "images/playType/kol/virtualDesktop/1.webp",
      icon:
        process.env.resourceUrl +
        "images/playType/kol/virtualDesktop/icon1.webp",
      name: "Patrick Rambles",
      desc: "Apple AR is already here! - XREAL AIR for Mac!",
      media: "youtube",
      link:
        "https://www.youtube.com/watch?v=bhLE8KJgwww&list=PLL5TxFqWatXdU_t5mCTG_VsGrAcJY2kya",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/virtualDesktop/2.webp",
      icon:
        process.env.resourceUrl +
        "images/playType/kol/virtualDesktop/icon2.webp",
      name: "Brandon Butch",
      desc: "I Wore Smart Glasses with a MacBook for 3 Weeks",
      media: "youtube",
      link:
        "https://www.youtube.com/watch?v=o6bJ8EgE6sI&list=PLL5TxFqWatXdU_t5mCTG_VsGrAcJY2kya&index=2",
    },
  ],
  list2: [
    {
      img: process.env.resourceUrl + "images/playType/kol/virtualDesktop/3.webp",
      icon:
        process.env.resourceUrl +
        "images/playType/kol/virtualDesktop/icon3.webp",
      name: "Mark Ellis Reviews",
      desc: "Why you SHOULDN'T wait Apple Vision! XREAL Air review",
      media: "youtube",
      link:
        "https://www.youtube.com/watch?v=lrrG1UJ613Q&list=PLL5TxFqWatXdU_t5mCTG_VsGrAcJY2kya&index=3",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/virtualDesktop/4.webp",
      icon:
        process.env.resourceUrl +
        "images/playType/kol/virtualDesktop/icon4.webp",
      name: "Max Tech ",
      desc: "Sorry, Vision Pro! XREAL Air is SHOCKINGLY Good for $379",
      link:
        "https://www.youtube.com/watch?v=_9r_d6Au0eE&list=PLL5TxFqWatXdU_t5mCTG_VsGrAcJY2kya&index=4",
      media: "youtube",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/virtualDesktop/5.webp",
      icon:
        process.env.resourceUrl +
        "images/playType/kol/virtualDesktop/icon5.webp",
      name: "Luke Miani",
      desc: "Why wait for Apple Glass?",
      media: "youtube",
      link:
        "https://www.youtube.com/watch?v=3-PIi4I9-j8&list=PLL5TxFqWatXdU_t5mCTG_VsGrAcJY2kya&index=5",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/virtualDesktop/6.webp",
      icon:
        process.env.resourceUrl +
        "images/playType/kol/virtualDesktop/icon6.webp",
      name: "AppleTrack",
      desc: "Apple AR Glasses Tech Preview! (Nreal Air)",
      media: "youtube",
      link:
        "https://www.youtube.com/watch?v=V5T6DGo87eQ&list=PLL5TxFqWatXdU_t5mCTG_VsGrAcJY2kya&index=6",
    },

    {
      img: process.env.resourceUrl + "images/playType/kol/virtualDesktop/7.webp",
      icon:
        process.env.resourceUrl +
        "images/playType/kol/virtualDesktop/icon7.webp",
      name: "Craft Computing",
      desc: "The Best Gets Better - Nreal Air Head Mounted Display",
      media: "youtube",
      link:
        "https://www.youtube.com/watch?v=RRHC1cB8d7g&list=PLL5TxFqWatXdU_t5mCTG_VsGrAcJY2kya&index=7",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/virtualDesktop/8.webp",
      icon:
        process.env.resourceUrl +
        "images/playType/kol/virtualDesktop/icon8.webp",
      name: "AppleInsider",
      desc: "Experience Spatial Computing NOW With Xreal Air AR Glasses!",
      link:
        "https://www.youtube.com/watch?v=JmHU494J44s&list=PLL5TxFqWatXdU_t5mCTG_VsGrAcJY2kya&index=8",
      media: "youtube",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/virtualDesktop/9.webp",
      icon:
        process.env.resourceUrl +
        "images/playType/kol/virtualDesktop/icon9.webp",
      name: "PAINFULLY HONEST TECH",
      desc: "Forget About Apple Vision Pro? Xreal Air for Mac",
      link:
        "https://www.youtube.com/watch?v=75hxrV0CHmQ&list=PLL5TxFqWatXdU_t5mCTG_VsGrAcJY2kya&index=10",
      media: "youtube",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/virtualDesktop/10.webp",
      icon:
        process.env.resourceUrl +
        "images/playType/kol/virtualDesktop/icon10.webp",
      name: "Karl Conrad",
      desc: "BUDGET Apple Vision Pro? - XREAL Air Headset",
      link:
        "https://www.youtube.com/watch?v=G113r3ITx7M&list=PLL5TxFqWatXdU_t5mCTG_VsGrAcJY2kya&index=11",
      media: "youtube",
    },


    //arspace
    {
      img: process.env.resourceUrl + "images/playType/kol/arSpace/7.webp",
      icon: process.env.resourceUrl + "images/playType/kol/arSpace/icon7.webp",
      name: "ETA PRIME",
      desc:
        'NReal Air AR Glasses Hands-On, A 201" Micro-OLED Screen On Your Face!',
      media: "youtube",
      link:
        "https://www.youtube.com/watch?v=8rHLIH6kJ1c&list=PLL5TxFqWatXdBEeUzRBhMNZCCH03UHLL1&index=7",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/arSpace/8.webp",
      icon: process.env.resourceUrl + "images/playType/kol/arSpace/icon8.webp",
      name: "Flossy Carter",
      desc: "Nreal Air AR Glasses - These Just BLEW MY MIND!",
      media: "youtube",
      link:
        "https://www.youtube.com/watch?v=_VloUjnGoLM&list=PLL5TxFqWatXdBEeUzRBhMNZCCH03UHLL1&index=10",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/arSpace/9.webp",
      icon: process.env.resourceUrl + "images/playType/kol/arSpace/icon9.webp",
      name: "mryeester",
      desc: "Are AR Sunglasses the Future of Mobile Gaming?",
      media: "youtube",
      link:
        "https://www.youtube.com/watch?v=ALMip31arj4&list=PLL5TxFqWatXdBEeUzRBhMNZCCH03UHLL1&index=8",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/arSpace/10.webp",
      icon:
        process.env.resourceUrl +
        "images/playType/kol/arSpace/icon10.webp",
      name: "UFD Tech",
      desc: "Holy Crap! I've Been Waiting for This for YEARS",
      link:
        "https://www.youtube.com/watch?v=-cKNT0mxx1I&list=PLL5TxFqWatXdBEeUzRBhMNZCCH03UHLL1&index=9",
      media: "youtube",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/arSpace/11.webp",
      icon:
        process.env.resourceUrl +
        "images/playType/kol/arSpace/icon11.webp",
      name: "Matthew Moniz",
      desc: "XREAL Air AR Glasses - 130\" OLED Screen That Fits in Your Pocket!",
      link:
        "https://www.youtube.com/watch?v=wvJ5l6Ljmqg&list=PLL5TxFqWatXdBEeUzRBhMNZCCH03UHLL1&index=11",
      media: "youtube",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/arSpace/12.webp",
      icon:
        process.env.resourceUrl +
        "images/playType/kol/arSpace/icon12.webp",
      name: "BE AMAZED",
      desc: "Gamers Who Achieved The IMPOSSIBLE",
      link:
        "https://www.youtube.com/watch?v=_3FLE2yrbRg&list=PLL5TxFqWatXdBEeUzRBhMNZCCH03UHLL1&index=12",
      media: "youtube",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/arSpace/13.webp",
      icon:
        process.env.resourceUrl +
        "images/playType/kol/arSpace/icon13.webp",
      name: "TechSpurt",
      desc: "NReal Air AR Glasses | Cinema in your pocket!",
      link:
        "https://www.youtube.com/watch?v=qPmzjZ48uhc&list=PLL5TxFqWatXdBEeUzRBhMNZCCH03UHLL1&index=13",
      media: "youtube",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/arSpace/14.webp",
      icon:
        process.env.resourceUrl +
        "images/playType/kol/arSpace/icon14.webp",
      name: "Snazzy Labs",
      desc: "Fix the Steam Deck's Biggest Problem!",
      link:
        "https://www.youtube.com/watch?v=5pZbVks7J_0&list=PLL5TxFqWatXdBEeUzRBhMNZCCH03UHLL1&index=14",
      media: "youtube",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/arSpace/15.webp",
      icon:
        process.env.resourceUrl +
        "images/playType/kol/arSpace/icon15.webp",
      name: "zollotech",
      desc: "XRAEL Air (Formerly Nreal) - 201-inch Display for iPhone",
      link:
        "https://www.youtube.com/watch?v=J0GUDW3ElvQ&list=PLL5TxFqWatXdBEeUzRBhMNZCCH03UHLL1&index=15",
      media: "youtube",
    },
    {
      img: process.env.resourceUrl + "images/playType/kol/arSpace/16.webp",
      icon:
        process.env.resourceUrl +
        "images/playType/kol/arSpace/icon16.webp",
      name: "MkElite",
      desc: "I Played Warzone 2 in AR 210\" SCREEN! Xreal AIR AR Glasses 2023 Review",
      link:
        "https://www.youtube.com/watch?v=zxUi1zBiRLE&list=PLL5TxFqWatXdBEeUzRBhMNZCCH03UHLL1&index=16",
      media: "youtube",
    }
  ]
}

const buy_link = {
  'base': {
    shop: "https://us.shop.xreal.com/",
    air2ultra:{
      shop:'https://us.shop.xreal.com/products/xreal-air-2-ultra/'
    },
    air2: {
      shop: 'https://us.shop.xreal.com/products/xreal-air-2',
      amazon: 'https://www.amazon.com/dp/B0CH11V8B9?maas=maas_adg_BC22DB3B26A7548A524A93589A2A614B_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'$339.15',
    },
    air2pro: {
      shop: 'https://us.shop.xreal.com/products/xreal-air-2-pro',
      amazon: 'https://www.amazon.com/dp/B0CHVQWW4P?ref=myi_title_dp',
      sale_shop_price:'$399.00',

    },
    air: {
      shop: 'https://us.shop.xreal.com/products/xreal-air',
      // amazon: 'https://www.amazon.com/dp/B0C3MKPLHP?maas=maas_adg_989C1A1FBA7F5EF014BA9EC34533FD28_afap_abs&ref_=aa_maas&tag=maas',
      // https://www.amazon.com/gp/product/B0CRTT1CVR?maas=maas_adg_579FA56A330F7CF406E283616C353C7C_afap_abs&ref_=aa_maas&tag=maas
      amazon:"",
      sale_shop_price:'$197.00',
      // sale_shop_reduce:'-$100'
    },
    air_beam:{
      shop:"https://us.shop.xreal.com/products/xreal-air-xreal-beam-bundle",
      price:"$399.00",
      sale_price:'$318.00',

    },
    air_ada:{
      shop:"https://us.shop.xreal.com/products/xreal-air-xreal-adapter-bundle",
      price:"$388.00"

    },
    beam: {
      shop: "https://us.shop.xreal.com/products/xreal-beam",
      amazon: 'https://www.amazon.com/dp/B0CC94Z9LX?maas=maas_adg_A9593CAAF5584BC2E1E0552E3CE287B6_afap_abs&ref_=aa_maas&tag=maas',
    },
    adapter: {
      shop: "https://us.shop.xreal.com/products/xreal-adapter",
      amazon: 'https://www.amazon.com/dp/B0BF53342D?maas=maas_adg_FD49D55FDA21B13E360A9B89F98938B8_afap_abs&ref_=aa_maas&tag=maas',
    },
    sticker: {
      shop: "https://us.shop.xreal.com/products/stickers-for-xreal-air-air2",
      amazon: ""
    },
    beampro: {
      shop: 'https://us.shop.xreal.com/products/xreal-beam-pro',
      amazon:"",
      shop_price_128:'$199.00',
      shop_price_256:'$249.00',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2pro:{
      shop: 'https://us.shop.xreal.com/products/xreal-beam-pro-air2-pro-bundle',
      amazon:"",
      shop_price_128:'$648.00',
      shop_price_256:'$698.00',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2:{
      shop: 'https://us.shop.xreal.com/products/xreal-beam-pro-air2-bundle',
      amazon:"",
      shop_price_128:'$598.00',
      shop_price_256:'$648.00',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    hub:{
      shop:'https://us.shop.xreal.com/products/xreal-hub'
    }
  },
  'us': {
    shop: "https://us.shop.xreal.com/",
    air2ultra:{
      shop:'https://us.shop.xreal.com/products/xreal-air-2-ultra/'
    },
    air2: {
      shop: 'https://us.shop.xreal.com/products/xreal-air-2',
      amazon: 'https://www.amazon.com/dp/B0CH11V8B9?maas=maas_adg_BC22DB3B26A7548A524A93589A2A614B_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'$339.15',
    },
    air2pro: {
      shop: 'https://us.shop.xreal.com/products/xreal-air-2-pro',
      amazon: 'https://www.amazon.com/dp/B0CHVQWW4P?ref=myi_title_dp',
      sale_shop_price:'$399.00',

    },
    air: {
      shop: 'https://us.shop.xreal.com/products/xreal-air',
      // amazon: 'https://www.amazon.com/dp/B0C3MKPLHP?maas=maas_adg_989C1A1FBA7F5EF014BA9EC34533FD28_afap_abs&ref_=aa_maas&tag=maas',
      // https://www.amazon.com/gp/product/B0CRTT1CVR?maas=maas_adg_579FA56A330F7CF406E283616C353C7C_afap_abs&ref_=aa_maas&tag=maas
      amazon:"",
      sale_shop_price:'$197.00',
      // sale_shop_reduce:'-$100'
    },
    air_beam:{
      shop:"https://us.shop.xreal.com/products/xreal-air-xreal-beam-bundle",
      price:"$399.00",
      sale_price:'$318.00',

    },
    air_ada:{
      shop:"https://us.shop.xreal.com/products/xreal-air-xreal-adapter-bundle",
      price:"$388.00"

    },
    beam: {
      shop: "https://us.shop.xreal.com/products/xreal-beam",
      amazon: 'https://www.amazon.com/dp/B0CC94Z9LX?maas=maas_adg_A9593CAAF5584BC2E1E0552E3CE287B6_afap_abs&ref_=aa_maas&tag=maas',
    },
    adapter: {
      shop: "https://us.shop.xreal.com/products/xreal-adapter",
      amazon: 'https://www.amazon.com/dp/B0BF53342D?maas=maas_adg_FD49D55FDA21B13E360A9B89F98938B8_afap_abs&ref_=aa_maas&tag=maas',
    },
    sticker: {
      shop: "https://us.shop.xreal.com/products/stickers-for-xreal-air-air2",
      amazon: ""
    },
    beampro: {
      shop: 'https://us.shop.xreal.com/products/xreal-beam-pro',
      amazon:"",
      shop_price_128:'$199.00',
      shop_price_256:'$249.00',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2pro:{
      shop: 'https://us.shop.xreal.com/products/xreal-beam-pro-air2-pro-bundle',
      amazon:"",
      shop_price_128:'$648.00',
      shop_price_256:'$698.00',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2:{
      shop: 'https://us.shop.xreal.com/products/xreal-beam-pro-air2-bundle',
      amazon:"",
      shop_price_128:'$598.00',
      shop_price_256:'$648.00',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    hub:{
      shop:'https://us.shop.xreal.com/products/xreal-hub'
    }
  },
  'jp': {
    shop: "https://jp.shop.xreal.com/",
    air2ultra:{
      shop:'https://jp.shop.xreal.com/products/xreal-air-2-ultra/'
    },
    air2: {
      shop: 'https://jp.shop.xreal.com/products/xreal-air-2',
      amazon: 'https://www.amazon.co.jp/gp/product/B0CJXLKRSZ?th=1',
      // sale_shop_price:'¥45,980'
    },
    air2pro: {
      shop: 'https://jp.shop.xreal.com/products/xreal-air-2-pro',
      amazon: 'https://www.amazon.co.jp/gp/product/B0CJ4MKNPQ',
      // sale_shop_price:'¥52,680',
    },
    air: {
      shop: 'https://jp.shop.xreal.com/products/xreal-air',
      amazon: 'https://www.amazon.co.jp/dp/B0C4KJ28GB?ref=myi_title_dp',
      // sale_shop_price:'¥36,980',
    },
    air_beam:{
      shop:"https://jp.shop.xreal.com/products/xreal-air-xreal-beam-bundle",
      price:"¥62,960",
      // sale_price:"¥53,510"
    },
    air_ada:{
      shop:"https://jp.shop.xreal.com/products/xreal-air-xreal-adapter-bundle",
      price:"¥58,960"
    },
    beam: {
      shop: "https://jp.shop.xreal.com/products/xreal-beam",
      amazon: 'https://www.amazon.co.jp/dp/B0CD1N7KRM?ref=myi_title_dp',
    },
    adapter: {
      shop: "https://jp.shop.xreal.com/products/xreal-adapter",
      amazon: 'https://www.amazon.co.jp/dp/B0C4KJP6VB?ref=myi_title_dp',
    },
    sticker: {
      shop: "https://jp.shop.xreal.com/products/stickers-for-xreal-air-air2",
      amazon: ""
    },
    beampro: {
      shop: 'https://jp.shop.xreal.com/en/products/xreal-beam-pro',
      amazon:"",
      shop_price_128:'¥32980',
      shop_price_256:'¥39980',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2pro:{
      shop: 'https://jp.shop.xreal.com/en/products/xreal-beam-pro-air2-pro-bundle-copy',
      amazon:"",
      shop_price_128:'¥94960',
      shop_price_256:'¥101960',
      sale_shop_price_128:'¥85460',
      sale_shop_price_256:'¥91760'
    },
    beampro_air2:{
      shop: 'https://jp.shop.xreal.com/en/products/xreal-beam-pro-air2-bundle',
      amazon:"",
      shop_price_128:'¥87960',
      shop_price_256:'¥94960',
      sale_shop_price_128:'¥79160',
      sale_shop_price_256:'¥85460'
    }, hub:{
      shop:'https://jp.shop.xreal.com/products/xreal-hub-1'
    }
  },
  'kr': {
    shop: "https://xreal.kr/",
    air2ultra:{
      shop:'https://xreal.kr/surl/O/32'
    },
    air2: {
      shop: '',
      amazon: '',
    },
    air2pro: {
      shop: '',
      amazon: '',
    },
    air: {
      shop: 'https://xreal.kr/product/xreal-air-%EC%97%91%EC%8A%A4%EB%A6%AC%EC%96%BC-%EC%97%90%EC%96%B4/19/category/1/display/4/',
      amazon: '',
      
    },
    air_beam:{
      shop:"https://xreal.kr/product/xreal-beam-%EC%97%91%EC%8A%A4%EB%A6%AC%EC%96%BC-%EB%B9%94/23/category/71/display/2/",
      price:"667,000원"
    },
    air_ada:{
      shop:"https://xreal.kr/product/xreal-%EC%97%91%EC%8A%A4%EB%A6%AC%EC%96%BC-%EC%97%90%EC%96%B4-%EC%96%B4%EB%8C%91%ED%84%B0-%EC%84%B8%ED%8A%B8/26/category/1/display/4/",
      price:"570,000원"
    },
    beam: {
      shop: "https://xreal.kr/product/xreal-beam-%EC%97%91%EC%8A%A4%EB%A6%AC%EC%96%BC-%EB%B9%94/23/category/1/display/4/",
      amazon: ""
    },
    adapter: {
      shop: "https://xreal.kr/product/%EC%97%91%EC%8A%A4%EB%A6%AC%EC%96%BC-%EC%97%90%EC%96%B4-%EC%96%B4%EB%8C%91%ED%84%B0/21/category/1/display/4/",
      amazon: ""
    },
    sticker: {
      shop: "",
      amazon: ""
    },
    beampro: {
      shop: '',
      amazon:"",
    },
    beampro_air2pro:{
      shop: '',
      amazon:"",
    },
    beampro_air2:{
      shop: '',
      amazon:"",
    },
    hub:{
      shop:''
    }
  },
  'th': {
    shop: "https://shopee.co.th/xrealofficialstore",
    shop1: "https://www.lazada.co.th/shop/xrealofficialstore",
    air2ultra:{
      shop:''
    },
    air2: {
      shop: '',
      amazon: '',
    },
    air2pro: {
      shop: '',
      amazon: '',
    },
    air: {
      shop: '',
      amazon: '',
    },
    beam: {
      shop: "",
      amazon: ""
    },
    adapter: {
      shop: "",
      amazon: ""
    },
    sticker: {
      shop: "",
      amazon: ""
    },
    beampro: {
      shop: '',
      amazon:"",
    },
    beampro_air2pro:{
      shop: '',
      amazon:"",
    },
    beampro_air2:{
      shop: '',
      amazon:"",
    },
    hub:{
      shop: '',
      amazon:"",
    }
  },
  'uk': {
    shop: "https://uk.shop.xreal.com/",
    air2ultra:{
      shop:'https://uk.shop.xreal.com/products/xreal-air-2-ultra/'
    },
    air2: {
      shop: 'https://uk.shop.xreal.com/products/xreal-air-2',
      amazon:'https://www.amazon.co.uk/dp/B0CHVQWPKD?maas=maas_adg_ED5114BA59E110571798D61D89FA038C_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'£319.20',
    },
    air2pro: {
      shop: 'https://uk.shop.xreal.com/products/xreal-air-2-pro',
      amazon: 'https://www.amazon.co.uk/dp/B0CHVQC5TS?maas=maas_adg_67409AEAD887BA1E73830265B6DF257E_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'£381.65',

    },
    air: {
      shop: 'https://uk.shop.xreal.com/products/xreal-air',
      amazon: 'https://www.amazon.co.uk/gp/product/B0CG9Q5MQJ?maas=maas_adg_88B142B254660623F8EB1DDFEA3A771C_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'£288.15',
      // sale_shop_reduce:'-£80'


    },
    air_beam:{
      shop:"https://uk.shop.xreal.com/products/xreal-air-xreal-beam-bundle",
      price:"£498.00"
    },
    air_ada:{
      shop:"https://uk.shop.xreal.com/products/xreal-air-xreal-adapter-bundle",
      price:"£438.00"
    },
    beam: {
      shop: "https://uk.shop.xreal.com/products/xreal-beam",

      amazon: 'https://www.amazon.co.uk/dp/B0CGDX6QYX?maas=maas_adg_619C19D63A164F962DB0EE6D3FF2ED90_afap_abs&ref_=aa_maas&tag=maas',
    },
    adapter: {
      shop: "https://uk.shop.xreal.com/products/xreal-adapter",
      amazon: 'https://www.amazon.co.uk/dp/B0CGDVB322?maas=maas_adg_F58762497FA659270C4EF5E324C9DC97_afap_abs&ref_=aa_maas&tag=maas',
    },
    sticker: {
      shop: "https://uk.shop.xreal.com/products/stickers-for-xreal-air-air2",
      amazon: ""
    },
    beampro: {
      shop: 'https://uk.shop.xreal.com/products/xreal-beam-pro',
      amazon:"",
      shop_price_128:'£189.00',
      shop_price_256:'£239.00',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2pro:{
      shop: 'https://uk.shop.xreal.com/products/xreal-beam-pro-air2-pro-bundle',
      amazon:"",
      shop_price_128:'£638.00',
      shop_price_256:'£688.00',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2:{
      shop: 'https://uk.shop.xreal.com/products/xreal-beam-pro-air2-bundle',
      amazon:"",
      shop_price_128:'£588.00',
      shop_price_256:'£638.00',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },hub:{
      shop:''
    }
  },
  'de-en': {
    shop: "https://eu.shop.xreal.com/de-de",
    air2ultra:{
      shop:'https://eu.shop.xreal.com/products/xreal-air-2-ultra/'
    },
    air2: {
      shop: 'https://eu.shop.xreal.com/de-de/products/xreal-air-2',
      amazon: 'https://www.amazon.de/gp/product/B0CHVYP6NX?maas=maas_adg_98F5BCC496E20CFA460C9E87FA0B1A4A_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'389.00€',
    },
    air2pro: {
      shop: 'https://eu.shop.xreal.com/de-de/products/xreal-air-2-pro',
      amazon: 'https://www.amazon.de/gp/product/B0CHVZL1FR?maas=maas_adg_47310DF27D98EBB98AA35B7DE43DB0BE_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'435.00€',
    },
    air: {
      shop: 'https://eu.shop.xreal.com/de-de/products/xreal-air',
      amazon: 'https://www.amazon.de/gp/product/B0CCDPQGQT?maas=maas_adg_C62609F6DE53529F1EE37CC0B5C782AA_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'299.00€',
    },
    air_beam:{
      shop:"https://eu.shop.xreal.com/de-de/products/xreal-air-xreal-beam-bundle",
      price:"515.00€"
    },
    air_ada:{
      shop:"https://eu.shop.xreal.com/de-de/products/xreal-air-xreal-adapter-bundle",
      price:"425.00€"
    },
    beam: {
      shop: "https://eu.shop.xreal.com/de-de/products/xreal-beam",
      amazon: "https://www.amazon.de/gp/product/B0CGLWJFQG?maas=maas_adg_5616FF657E66D2D9BA8AA1649C5F2BFB_afap_abs&ref_=aa_maas&tag=maas"
    },
    adapter: {
      shop: "https://eu.shop.xreal.com/de-de/products/xreal-adapter",
      amazon: "https://www.amazon.de/gp/product/B0CGLYKP1T?maas=maas_adg_8B34890F05175C3245DE0330A017A05A_afap_abs&ref_=aa_maas&tag=maas"
    },
    sticker: {
      shop: "https://eu.shop.xreal.com/de-de/products/stickers-for-xreal-air-air2",
      amazon: ""
    },
    beampro: {
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro',
      amazon:"",
      shop_price_128:'229.00€',
      shop_price_256:'279.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2pro:{
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro-air2-pro',
      amazon:"",
      shop_price_128:'744.00€',
      shop_price_256:'794.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2:{
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro-air2-bundle',
      amazon:"",
      shop_price_128:'688.00€',
      shop_price_256:'738.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },hub:{
      shop:'https://eu.shop.xreal.com/de-de/products/xreal-hub'
    }
  },
  'de': {
    shop: "https://eu.shop.xreal.com/de-de",
    air2ultra:{
      shop:'https://eu.shop.xreal.com/products/xreal-air-2-ultra/'
    },
    air2: {
      shop: 'https://eu.shop.xreal.com/de-de/products/xreal-air-2',
      amazon: 'https://www.amazon.de/gp/product/B0CHVYP6NX?maas=maas_adg_98F5BCC496E20CFA460C9E87FA0B1A4A_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'389.00€',
    },
    air2pro: {
      shop: 'https://eu.shop.xreal.com/de-de/products/xreal-air-2-pro',
      amazon: 'https://www.amazon.de/gp/product/B0CHVZL1FR?maas=maas_adg_47310DF27D98EBB98AA35B7DE43DB0BE_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'435.00€',
    },
    air: {
      shop: 'https://eu.shop.xreal.com/de-de/products/xreal-air',
      amazon: 'https://www.amazon.de/gp/product/B0CCDPQGQT?maas=maas_adg_C62609F6DE53529F1EE37CC0B5C782AA_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'299.00€',
    },
    air_beam:{
      shop:"https://eu.shop.xreal.com/de-de/products/xreal-air-xreal-beam-bundle",
      price:"515.00€"
    },
    air_ada:{
      shop:"https://eu.shop.xreal.com/de-de/products/xreal-air-xreal-adapter-bundle",
      price:"425.00€"
    },
    beam: {
      shop: "https://eu.shop.xreal.com/de-de/products/xreal-beam",
      amazon: "https://www.amazon.de/gp/product/B0CGLWJFQG?maas=maas_adg_5616FF657E66D2D9BA8AA1649C5F2BFB_afap_abs&ref_=aa_maas&tag=maas"
    },
    adapter: {
      shop: "https://eu.shop.xreal.com/de-de/products/xreal-adapter",
      amazon: "https://www.amazon.de/gp/product/B0CGLYKP1T?maas=maas_adg_8B34890F05175C3245DE0330A017A05A_afap_abs&ref_=aa_maas&tag=maas"
    },
    sticker: {
      shop: "https://eu.shop.xreal.com/de-de/products/stickers-for-xreal-air-air2",
      amazon: ""
    },
    beampro: {
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro',
      amazon:"",
      shop_price_128:'229.00€',
      shop_price_256:'279.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2pro:{
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro-air2-pro',
      amazon:"",
      shop_price_128:'744.00€',
      shop_price_256:'794.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2:{
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro-air2-bundle',
      amazon:"",
      shop_price_128:'688.00€',
      shop_price_256:'738.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },hub:{
      shop:'https://eu.shop.xreal.com/de-de/products/xreal-hub'
    }
  },
  'fr': {
    shop: "https://eu.shop.xreal.com/fr",
    air2ultra:{
      shop:'https://eu.shop.xreal.com/products/xreal-air-2-ultra/'
    },
    air2: {
      shop: 'https://eu.shop.xreal.com/fr/products/xreal-air-2',
      amazon: 'https://www.amazon.fr/gp/product/B0CHVYP6NX?maas=maas_adg_7C54DA33076DC07FCFAC03BC1568E221_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'389.00€',
    },
    air2pro: {
      shop: 'https://eu.shop.xreal.com/fr/products/xreal-air-2-pro',
      amazon: 'https://www.amazon.fr/gp/product/B0CHVZL1FR?maas=maas_adg_78C83A52DB4DDB49C690089F4F1990C3_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'435.00€',
    },
    air: {
      shop: 'https://eu.shop.xreal.com/fr/products/xreal-air',
      amazon: 'https://www.amazon.de/gp/product/B0CCDPQGQT?maas=maas_adg_C62609F6DE53529F1EE37CC0B5C782AA_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'299.00€',
    },
    air_beam:{
      shop:"https://eu.shop.xreal.com/fr/products/xreal-air-xreal-beam-bundle",
      price:"515.00€"
    },
    air_ada:{
      shop:"https://eu.shop.xreal.com/fr/products/xreal-air-xreal-adapter-bundle",
      price:"425.00€"
    },
    beam: {
      shop: "https://eu.shop.xreal.com/fr/products/xreal-beam",
      amazon: 'https://www.amazon.fr/gp/product/B0CGLWJFQG?maas=maas_adg_8DD838AE3B539423FA28625A0D82E6D3_afap_abs&ref_=aa_maas&tag=maas',
    },
    adapter: {
      shop: "https://eu.shop.xreal.com/fr/products/xreal-adapter",
      amazon: 'https://www.amazon.fr/gp/product/B0CGLYKP1T?maas=maas_adg_47F6042DF1ECBD50A443636115C142DC_afap_abs&ref_=aa_maas&tag=maas',
    },
    sticker: {
      shop: "https://eu.shop.xreal.com/fr/products/stickers-for-xreal-air-air2",
      amazon: ""
    },
    beampro: {
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro',
      amazon:"",
      shop_price_128:'229.00€',
      shop_price_256:'279.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2pro:{
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro-air2-pro',
      amazon:"",
      shop_price_128:'744.00€',
      shop_price_256:'794.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2:{
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro-air2-bundle',
      amazon:"",
      shop_price_128:'688.00€',
      shop_price_256:'738.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },hub:{
      shop:'https://eu.shop.xreal.com/fr/products/xreal-hub'
    }
  },
  'fr-en': {
    shop: "https://eu.shop.xreal.com/fr",
    air2ultra:{
      shop:'https://eu.shop.xreal.com/products/xreal-air-2-ultra/'
    },
    air2: {
      shop: 'https://eu.shop.xreal.com/fr/products/xreal-air-2',
      amazon: 'https://www.amazon.fr/gp/product/B0CHVYP6NX?maas=maas_adg_7C54DA33076DC07FCFAC03BC1568E221_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'389.00€',
    },
    air2pro: {
      shop: 'https://eu.shop.xreal.com/fr/products/xreal-air-2-pro',
      amazon: 'https://www.amazon.fr/gp/product/B0CHVZL1FR?maas=maas_adg_78C83A52DB4DDB49C690089F4F1990C3_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'435.00€',
    },
    air: {
      shop: 'https://eu.shop.xreal.com/fr/products/xreal-air',
      amazon: 'https://www.amazon.de/gp/product/B0CCDPQGQT?maas=maas_adg_C62609F6DE53529F1EE37CC0B5C782AA_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'299.00€',
    },
    air_beam:{
      shop:"https://eu.shop.xreal.com/fr/products/xreal-air-xreal-beam-bundle",
      price:"515.00€"
    },
    air_ada:{
      shop:"https://eu.shop.xreal.com/fr/products/xreal-air-xreal-adapter-bundle",
      price:"425.00€"
    },
    beam: {
      shop: "https://eu.shop.xreal.com/fr/products/xreal-beam",
      amazon: 'https://www.amazon.fr/gp/product/B0CGLWJFQG?maas=maas_adg_8DD838AE3B539423FA28625A0D82E6D3_afap_abs&ref_=aa_maas&tag=maas',
    },
    adapter: {
      shop: "https://eu.shop.xreal.com/fr/products/xreal-adapter",
      amazon: 'https://www.amazon.fr/gp/product/B0CGLYKP1T?maas=maas_adg_47F6042DF1ECBD50A443636115C142DC_afap_abs&ref_=aa_maas&tag=maas',
    },
    sticker: {
      shop: "https://eu.shop.xreal.com/fr/products/stickers-for-xreal-air-air2",
      amazon: ""
    }, beampro: {
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro',
      amazon:"",
      shop_price_128:'229.00€',
      shop_price_256:'279.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2pro:{
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro-air2-pro',
      amazon:"",
      shop_price_128:'744.00€',
      shop_price_256:'794.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2:{
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro-air2-bundle',
      amazon:"",
      shop_price_128:'688.00€',
      shop_price_256:'738.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },hub:{
      shop:'https://eu.shop.xreal.com/fr/products/xreal-hub'
    }
  },
  'it': {
    shop: "https://eu.shop.xreal.com/it-it",
    air2ultra:{
      shop:'https://eu.shop.xreal.com/products/xreal-air-2-ultra/'
    },
    air2: {
      shop: 'https://eu.shop.xreal.com/it-it/products/xreal-air-2',
      amazon: 'https://www.amazon.it/gp/product/B0CHVYP6NX?maas=maas_adg_65F32071545C8A9C1147C1893023C59C_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'389.00€',
    },
    air2pro: {
      shop: 'https://eu.shop.xreal.com/it-it/products/xreal-air-2-pro',
      amazon: 'https://www.amazon.it/gp/product/B0CHVZL1FR?maas=maas_adg_9C9CEEC2351E43725BA860593B49C427_afap_abs&ref_=aa_maas&tag=maas',
        // sale_shop_price:'435.00€',
    },
    air: {
      shop: 'https://eu.shop.xreal.com/it-it/products/xreal-air',
      amazon: 'https://www.amazon.de/gp/product/B0CCDPQGQT?maas=maas_adg_C62609F6DE53529F1EE37CC0B5C782AA_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'299.00€',

    },
    air_beam:{
      shop:"https://eu.shop.xreal.com/it-it/products/xreal-air-xreal-beam-bundle",
      price:"515.00€"
    },
    air_ada:{
      shop:"https://eu.shop.xreal.com/it-it/products/xreal-air-xreal-adapter-bundle",
      price:"425.00€"
    },
    beam: {
      shop: "https://eu.shop.xreal.com/it-it/products/xreal-beam",
      amazon: 'https://www.amazon.it/gp/product/B0CGLWJFQG?maas=maas_adg_A661C4DB276E4579B34557E57198D519_afap_abs&ref_=aa_maas&tag=maas',
    },
    adapter: {
      shop: "https://eu.shop.xreal.com/it-it/products/xreal-adapter",
      amazon: 'https://www.amazon.it/gp/product/B0CGLYKP1T?maas=maas_adg_E30E8F7E4B997F076792A31682FE4D42_afap_abs&ref_=aa_maas&tag=maas',
    },
    sticker: {
      shop: "https://eu.shop.xreal.com/it-it/products/stickers-for-xreal-air-air2",
      amazon: ""
    }, beampro: {
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro',
      amazon:"",
      shop_price_128:'229.00€',
      shop_price_256:'279.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2pro:{
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro-air2-pro',
      amazon:"",
      shop_price_128:'744.00€',
      shop_price_256:'794.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2:{
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro-air2-bundle',
      amazon:"",
      shop_price_128:'688.00€',
      shop_price_256:'738.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },hub:{
      shop:'https://eu.shop.xreal.com/it-it/products/xreal-hub'
    }
  },
  'it-en': {
    shop: "https://eu.shop.xreal.com/it-it",
    air2ultra:{
      shop:'https://eu.shop.xreal.com/products/xreal-air-2-ultra/'
    },
    air2: {
      shop: 'https://eu.shop.xreal.com/it-it/products/xreal-air-2',
      amazon: 'https://www.amazon.it/gp/product/B0CHVYP6NX?maas=maas_adg_65F32071545C8A9C1147C1893023C59C_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'389.00€',
    },
    air2pro: {
      shop: 'https://eu.shop.xreal.com/it-it/products/xreal-air-2-pro',
      amazon: 'https://www.amazon.it/gp/product/B0CHVZL1FR?maas=maas_adg_9C9CEEC2351E43725BA860593B49C427_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'435.00€',
    },
    air: {
      shop: 'https://eu.shop.xreal.com/it-it/products/xreal-air',
      amazon: 'https://www.amazon.de/gp/product/B0CCDPQGQT?maas=maas_adg_C62609F6DE53529F1EE37CC0B5C782AA_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'299.00€',

    },
    air_beam:{
      shop:"https://eu.shop.xreal.com/it-it/products/xreal-air-xreal-beam-bundle",
      price:"515.00€"
    },
    air_ada:{
      shop:"https://eu.shop.xreal.com/it-it/products/xreal-air-xreal-adapter-bundle",
      price:"425.00€"
    },
    beam: {
      shop: "https://eu.shop.xreal.com/it-it/products/xreal-beam",
      amazon: 'https://www.amazon.it/gp/product/B0CGLWJFQG?maas=maas_adg_A661C4DB276E4579B34557E57198D519_afap_abs&ref_=aa_maas&tag=maas',
    },
    adapter: {
      shop: "https://eu.shop.xreal.com/it-it/products/xreal-adapter",
      amazon: 'https://www.amazon.it/gp/product/B0CGLYKP1T?maas=maas_adg_E30E8F7E4B997F076792A31682FE4D42_afap_abs&ref_=aa_maas&tag=maas',
    },
    sticker: {
      shop: "https://eu.shop.xreal.com/it-it/products/stickers-for-xreal-air-air2",
      amazon: ""
    }, beampro: {
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro',
      amazon:"",
      shop_price_128:'229.00€',
      shop_price_256:'279.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2pro:{
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro-air2-pro',
      amazon:"",
      shop_price_128:'744.00€',
      shop_price_256:'794.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2:{
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro-air2-bundle',
      amazon:"",
      shop_price_128:'688.00€',
      shop_price_256:'738.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },hub:{
      shop:'https://eu.shop.xreal.com/it-it/products/xreal-hub'
    }
  },
  'nl-en': {
    shop: "https://eu.shop.xreal.com/en-nl",
    air2ultra:{
      shop:'https://eu.shop.xreal.com/products/xreal-air-2-ultra/'
    },
    air2: {
      shop: 'https://eu.shop.xreal.com/en-nl/products/xreal-air-2',
      amazon: '',
      // sale_shop_price:'389.00€',
    },
    air2pro: {
      shop: 'https://eu.shop.xreal.com/en-nl/products/xreal-air-2-pro',
      amazon: '',
      // sale_shop_price:'435.00€',
    },
    air: {
      shop: 'https://eu.shop.xreal.com/en-nl/products/xreal-air',
      amazon: 'https://www.amazon.de/gp/product/B0CCDPQGQT?maas=maas_adg_C62609F6DE53529F1EE37CC0B5C782AA_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'299.00€',
 
    },
    air_beam:{
      shop:"https://eu.shop.xreal.com/en-nl/products/xreal-air-xreal-beam-bundle",
      price:"515.00€"
    },
    air_ada:{
      shop:"https://eu.shop.xreal.com/en-nl/products/xreal-air-xreal-adapter-bundle",
      price:"425.00€"
    },
    beam: {
      shop: "https://eu.shop.xreal.com/en-nl/products/xreal-beam",
      amazon: ""
    },
    adapter: {
      shop: "https://eu.shop.xreal.com/en-nl/products/xreal-adapter",
      amazon: ""
    },
    sticker: {
      shop: "https://eu.shop.xreal.com/en-nl/products/stickers-for-xreal-air-air2",
      amazon: ""
    }, beampro: {
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro',
      amazon:"",
      shop_price_128:'229.00€',
      shop_price_256:'279.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2pro:{
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro-air2-pro',
      amazon:"",
      shop_price_128:'744.00€',
      shop_price_256:'794.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2:{
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro-air2-bundle',
      amazon:"",
      shop_price_128:'688.00€',
      shop_price_256:'738.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },hub:{
      shop:'https://eu.shop.xreal.com/en-nl/products/xreal-hub'
    }
  },
  'cz-en': {
    shop: "https://eu.shop.xreal.com/en-cz/",
    air2ultra:{
      shop:'https://eu.shop.xreal.com/products/xreal-air-2-ultra/'
    },
    air2: {
      shop: 'https://eu.shop.xreal.com/en-cz/products/xreal-air-2',
      amazon: '',
      // sale_shop_price:'389.00€',
    },
    air2pro: {
      shop: 'https://eu.shop.xreal.com/en-cz/products/xreal-air-2-pro',
      amazon: '',
      // sale_shop_price:'435.00€',
    },
    air: {
      shop: 'https://eu.shop.xreal.com/en-cz/products/xreal-air',
      amazon: 'https://www.amazon.de/gp/product/B0CCDPQGQT?maas=maas_adg_C62609F6DE53529F1EE37CC0B5C782AA_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'299.00€',

    },
    air_beam:{
      shop:"https://eu.shop.xreal.com/en-cz/products/xreal-air-xreal-beam-bundle",
      price:"515.00€"
    },
    air_ada:{
      shop:"https://eu.shop.xreal.com/en-cz/products/xreal-air-xreal-adapter-bundle",
      price:"425.00€"
    },
    beam: {
      shop: "https://eu.shop.xreal.com/en-cz/products/xreal-beam",
      amazon: ""
    },
    adapter: {
      shop: "https://eu.shop.xreal.com/en-cz/products/xreal-adapter",
      amazon: ""
    },
    sticker: {
      shop: "https://eu.shop.xreal.com/en-cz/products/stickers-for-xreal-air-air2",
      amazon: ""
    }, beampro: {
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro',
      amazon:"",
      shop_price_128:'229.00€',
      shop_price_256:'279.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2pro:{
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro-air2-pro',
      amazon:"",
      shop_price_128:'744.00€',
      shop_price_256:'794.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2:{
      shop: 'https://eu.shop.xreal.com/products/xreal-beam-pro-air2-bundle',
      amazon:"",
      shop_price_128:'688.00€',
      shop_price_256:'738.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },hub:{
      shop:'https://eu.shop.xreal.com/en-cz/products/xreal-hub'
    }
  },
  'es-en': {
    shop: "https://eu.shop.xreal.com/es",
    air2ultra:{
      shop:'https://eu.shop.xreal.com/es/products/xreal-air-2-ultra/'
    },
    air2: {
      shop: 'https://eu.shop.xreal.com/es/products/xreal-air-2',
      amazon: '',
      // sale_shop_price:'389.00€',
    },
    air2pro: {
      shop: 'https://eu.shop.xreal.com/es/products/xreal-air-2-pro',
      amazon: '',
      // sale_shop_price:'435.00€',
    },
    air: {
      shop: 'https://eu.shop.xreal.com/es/products/xreal-air',
      amazon: 'https://www.amazon.de/gp/product/B0CCDPQGQT?maas=maas_adg_C62609F6DE53529F1EE37CC0B5C782AA_afap_abs&ref_=aa_maas&tag=maas',
      // sale_shop_price:'299.00€',

    },
    air_beam:{
      shop:"https://eu.shop.xreal.com/es/products/xreal-air-xreal-beam-bundle",
      price:"515.00€"
    },
    air_ada:{
      shop:"https://eu.shop.xreal.com/es/products/xreal-air-xreal-adapter-bundle",
      price:"425.00€"
    },
    beam: {
      shop: "https://eu.shop.xreal.com/es/products/xreal-beam",
      amazon: ""
    },
    adapter: {
      shop: "https://eu.shop.xreal.com/es/products/xreal-adapter",
      amazon: ""
    },
    sticker: {
      shop: "https://eu.shop.xreal.com/es/products/stickers-for-xreal-air-air2",
      amazon: ""
    }, beampro: {
      shop: 'https://eu.shop.xreal.com/es/products/xreal-beam-pro',
      amazon:"",
      shop_price_128:'229.00€',
      shop_price_256:'279.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2pro:{
      shop: 'https://eu.shop.xreal.com/es/products/xreal-beam-pro-air2-pro',
      amazon:"",
      shop_price_128:'744.00€',
      shop_price_256:'794.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },
    beampro_air2:{
      shop: 'https://eu.shop.xreal.com/es/products/xreal-beam-pro-air2-bundle',
      amazon:"",
      shop_price_128:'688.00€',
      shop_price_256:'738.00€',
      sale_shop_price_128:'',
      sale_shop_price_256:''
    },hub:{
      shop:'https://eu.shop.xreal.com/es/products/xreal-hub'
    }
  },
}



const spec_price = {
  'base': {
    air2: `$359.00`,
    air2pro: `$449.00`,
    air: `$299.00`,
  },
  'us': {
    air2: `$359.00`,
    air2pro: `$449.00`,
    air: `$299.00`,
  },
  'jp': {
    air2: `￥54,980`,
    air2pro: `￥61,980`,
    air: `¥45,980`,
  },
  'kr': {
    air2: ``,
    air2pro: ``,
    air: `498,000원`,
  },
  'th': {
    air2: ``,
    air2pro: ``,
    air: ``,
  },
  'uk': {
    air2: `£329.00`,
    air2pro: `£449.00`,
    air: `£289.00`,
  },
  'de': {
    air2: `399.00€`,
    air2pro: `515.00€`,
    air: `299.00€`,
  },
  'de-en': {
    air2: `399.00€`,
    air2pro: `515.00€`,
    air: `299.00€`,
  },
  'fr': {
    air2: `399.00€`,
    air2pro: `515.00€`,
    air: `299.00€`,
  },
  'fr-en': {
    air2: `399.00€`,
    air2pro: `515.00€`,
    air: `299.00€`,
  },
  'it': {
    air2: `399.00€`,
    air2pro: `515.00€`,
    air: `299.00€`,
  },
  'it-en': {
    air2: `399.00€`,
    air2pro: `515.00€`,
    air: `299.00€`,
  },
  'nl': {
    air2: `399.00€`,
    air2pro: `515.00€`,
    air: `299.00€`,
  },
  'nl-en': {
    air2: `399.00€`,
    air2pro: `515.00€`,
    air: `299.00€`,
  },
  'cz': {
    air2: `399.00€`,
    air2pro: `515.00€`,
    air: `299.00€`,
  },
  'cz-en': {
    air2: `399.00€`,
    air2pro: `515.00€`,
    air: `299.00€`,
  },
  'es-en': {
    air2: `399.00€`,
    air2pro: `515.00€`,
    air: `299.00€`,
  },
}

export {
  kol_data,
  buy_link,
  spec_price
}